import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mcruz/Documents/Developer/gatsby/appuro-tanoariacoelho-adrego-com/src/components/layout.js";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import MdxLayout from "../components/mdxLayout.js";
import MdxCoverLayout from "../components/mdxCoverLayout.js";
import Seo from '../components/seo.js';
import ogImage from '../../static/og-image/ogg-image-default.png';
import GallerySection1 from '../components/sections/gallerySection1.js';
import GallerySection2 from '../components/sections/gallerySection2.js';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title={useIntl().formatMessage({
      id: "product-elaboration.title"
    })} description={useIntl().formatMessage({
      id: "description"
    })} image={'https://www.tanoariacoelho-adrego.com' + ogImage} siteUrl={'https://www.tanoariacoelho-adrego.com' + props.pageContext.frontmatter.path} mdxType="Seo" />
    <MdxCoverLayout mdxType="MdxCoverLayout" />
    <MdxLayout mdxType="MdxLayout">
      <h1><FormattedMessage id="product-elaboration.title" mdxType="FormattedMessage" /></h1>
      <p><strong parentName="p"><FormattedMessage id="product-elaboration.gallery1" mdxType="FormattedMessage" /></strong></p>
      <GallerySection1 mdxType="GallerySection1" />
      <p><strong parentName="p"><FormattedMessage id="product-elaboration.gallery2" mdxType="FormattedMessage" /></strong></p>
      <GallerySection2 mdxType="GallerySection2" />
    </MdxLayout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      